label {
	display: block;
}
.form-check,
.form-group > label {
	margin-top: 10px;
}

.login .card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.login .card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: flex;
	border-radius: 50%;
	background: #e1e1e1;
	justify-content: center;
	align-items: flex-end;
	font-size: 90px;
	line-height: 90px;
	overflow: hidden;
}

.profile-img-card > i {
	margin-bottom: -14px !important;
	color: #7d7d7d;
}

.form-group .btn-primary {
	margin-top: 20px;
	width: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
	width: 100%;
	height: 100%;
	background-color: lightsteelblue;
}

.toast-container .alert {
	margin-bottom: 0;
}

.tooltip-inner {
	background-color: #2f4fff !important;
	box-shadow: 0px 0px 4px black;
	opacity: 1 !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
	border-right-color: #2f4fff !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
	border-left-color: #2f4fff !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #2f4fff !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: #2f4fff !important;
}
.tooltip.show {
	opacity: 1;
}

@media (min-width: 1400px) {
	.postList-filter select {
		min-width: 125px !important;
	}
}

.post .card-header,
.post .card-footer .details {
	font-size: 0.8rem !important;
}
.post .card-header .bi {
	font-size: 0.6rem !important;
	margin-right: 0.15rem !important;
}
.post .card-body {
	font-size: 0.85rem !important;
	min-height: 200px;
	white-space: pre-wrap;
}
.post .card-footer .details .bi {
	font-size: 0.6rem !important;
	margin-right: 0.25rem !important;
}

#image-data,
#image-data-spinner {
	transition: opacity 0.25s ease-in-out;
	-moz-transition: opacity 0.25s ease-in-out;
	-webkit-transition: opacity 0.25s ease-in-out;
}

.modal {
	--bs-modal-width: 700px;
}

.color-selected {
	position: absolute;
	bottom: 0;
	line-height: 15px;
	right: 0;
	margin: 0 -2px -2px 0;
	color: lightgreen;
}

@font-face {
	font-family: 'Lato-Regular';
	src: url('/public/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}